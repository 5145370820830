import React, { useEffect, useRef, useState } from 'react';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MinusIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import QuillTextEditor from '../../../../../../components/textEditor/Quill-Text-Editor';
import HoverEditComponent from '../../../../../../components/HoverEditComponent';
import SmallLoadingSpinner from '../../../../../../components/loading/SmallLoadingSpinner';
import ScheduledSend from '../ScheduledSend';
import UserSearch from '../../../../../../components/comboboxes/UserSearch';
import MessageSent from '../MessageSent';
import MinimizedMessage from './MinimizedMessage';
import MessageContainer from './MessageContainer';
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import Templates from './Templates';

const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR);

const Message = ({ id }) => {
  const queryClient = useQueryClient();

  const { data: message, isLoading, isError } = useQuery({
    queryFn: bunmailApi.getFn({
      endpoint: 'get_message',
      params: [id],
    }),
    queryKey: ['email', id],
  });

  const [subject, setSubject] = useState(message?.subject || '');
  const [isMinimized, setIsMinimized] = useState(message?.is_minimized);
  const [toEmail, setToEmail] = useState(message?.to_email);
  const [ccEmail, setCcEmail] = useState(message?.cc_email);
  const [bccEmail, setBccEmail] = useState(message?.bcc_email);
  const [showCC, setShowCC] = useState(message?.cc_email ? true : false);
  const [showBCC, setShowBCC] = useState(message?.bcc_email ? true : false);
  const [hidden, setHidden] = useState(false);
  const [showSendingModal, setShowSendingModal] = useState(false);
  const [undoSend, setUndoSend] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [quillEditorAcceptOutsideChange, setQuillEditorAcceptOutsideChange] = useState(false);
  const options = {
    is_draft: false,
    route: "emails",
    search_params: { document_id: id },
  };

  const updateSubjectMutation = useMutation(
    (newSubject) => bunmailApi.patch({
      endpoint: 'update_subject',
      body: { id, subject: newSubject }
    }),
    {
      onMutate: async (newSubject) => {
        await queryClient.cancelQueries(['email', id]);
        const previousMessage = queryClient.getQueryData(['email', id]);
        queryClient.setQueryData(['email', id], old => ({
          ...old,
          subject: newSubject,
        }));
        return { previousMessage };
      },
      onError: (error, newSubject, context) => {
        queryClient.setQueryData(['email', id], context.previousMessage);
        setSubject(context.previousMessage.subject);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['email', id]);
      }
    }
  );

  const modifyLabelsMutation = useMutation(async () => {
    return bunmailApi.delete({
      endpoint: 'delete_draft',
      params: [id],
    });
  }, {
    onMutate: async () => {
      queryClient.setQueryData(['drafts_being_composed'],
        (old) => (old.filter((draft) => draft.id !== id)));
    },
    onError: (err, variables, context) => {
      // Rollback to the previous value
    },
    onSettled: () => {
      // Invalidate query to ensure the UI is up-to-date with server data
      queryClient.invalidateQueries('drafts_being_composed');

    },
  });




  const updateRecipientsMutation = useMutation(
    (newRecipients) => bunmailApi.patch({
      endpoint: 'update_recipients',
      body: { id, ...newRecipients }
    }),
    {
      onMutate: async (newRecipients) => {
        await queryClient.cancelQueries(['email', id]);
        const previousMessage = queryClient.getQueryData(['email', id]);
        queryClient.setQueryData(['email', id], old => ({
          ...old,
          ...newRecipients,
        }));
        return { previousMessage };
      },
      onError: (error, newRecipients, context) => {
        queryClient.setQueryData(['email', id], context.previousMessage);
        setToEmail(context.previousMessage.to_email);
        setCcEmail(context.previousMessage.cc_email);
        setBccEmail(context.previousMessage.bcc_email);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['email', id]);
      }
    }
  );

  const updateIsComposingMutation = useMutation(
    () => bunmailApi.patch({
      endpoint: 'update_is_being_composed',
      body: { id, is_composing: false }
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('drafts_being_composed');
      }
    }

  );

  const toggleMinimizedMutation = useMutation(
    () => bunmailApi.patch({
      endpoint: 'update_is_minimized',
      body: { id, is_minimized: !isMinimized }
    }),
    {
      onMutate: async () => {
        setIsMinimized(!isMinimized);
        await queryClient.cancelQueries(['email', id]);
        const previousMessage = queryClient.getQueryData(['email', id]);
        queryClient.setQueryData(['email', id], old => ({
          ...old,
          is_minimized: !isMinimized,
        }));
        return { previousMessage };
      },
      onError: (error, newIsMinimized, context) => {
        queryClient.setQueryData(['email', id], context.previousMessage);
        setIsMinimized(context.previousMessage.is_minimized);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['email', id]);
      }
    }
  );
  let intervalId;

  const startEmailSendMutation = useMutation(
    () => bunmailApi.patch({ endpoint: 'start_email_send', params: [id] }),
    {
      onMutate: () => {
        queryClient.setQueryData(['email', id], old => ({
          ...old, waiting_to_send: true,
        }));
      }, 
      onSettled: () => {
        queryClient.invalidateQueries(['email', id]);

      },
    }
  );

  const cancelEmailSendMutation = useMutation(
    () => bunmailApi.patch({ endpoint: 'cancel_email_send', params: [id] }),
    {
      onMutate: () => {
        queryClient.setQueryData(['email', id], old => ({
          ...old, waiting_to_send: false,
        }));
      },
      onSettled: () => {
        queryClient.invalidateQueries(['email', id]);

      },
    }
  );


  const sendEmailMutation = useMutation(
    () => bunmailApi.patch({ endpoint: 'send_email', body: { id, } }),
    {
      onSuccess: () => {
        queryClient.setQueryData(['drafts_being_composed'],
          (old) => (old.filter((draft) => draft.id !== id)));
        queryClient.invalidateQueries('email', id);

      },
    }
  );

  //update state once message is fetched the first time
  const [isFetched, setIsFetched] = useState(false);
  useEffect(() => {
    if (message && !isFetched) {
      setSubject(message.subject);
      setToEmail(message.to_email);
      setCcEmail(message.cc_email);
      setBccEmail(message.bcc_email);
      setIsFetched(true);
      setIsMinimized(message.is_minimized);
      setShowCC(message.cc_email ? true : false);
      setShowBCC(message.bcc_email ? true : false);
      console.log(message.waiting_to_send)
      setShowSendingModal(message.waiting_to_send)
      setHidden(message.waiting_to_send)
    }
  }, [message?.id]);


  useEffect(() => {
    setSubject(message?.subject);
  },[message?.subject]);

  const toEmailArray = toEmail ? toEmail?.split(',').map((email) => email.trim()) : [];
  const ccEmailArray = ccEmail ? ccEmail?.split(',').map((email) => email.trim()) : [];
  const bccEmailArray = bccEmail ? bccEmail?.split(',').map((email) => email.trim()) : [];

  const undoSendRef = useRef(undoSend); // Create a ref for undoSend

  // Sync ref value with state
  useEffect(() => {
    undoSendRef.current = undoSend;
  }, [undoSend]);

  const handleSend = async () => {
    let count = 0;
    let intervalId;
    const timerMax = Number(queryClient.getQueryData('bunmail-settings')?.undo_send_timer) || 5

    try {
      setShowSendingModal(true);
      setHidden(true);
      startEmailSendMutation.mutate();
      intervalId = setInterval(() => {
        if (undoSendRef.current) { // Use the ref instead of state directly
          setHidden(false);
          setShowSendingModal(false);
          clearInterval(intervalId);
          setUndoSend(false);
          return;
        }
        console.log(`Sending email... (${count}/${timerMax})`);
       
        count++;
        if (count >= timerMax) {
          setShowSendingModal(false);
          sendEmailMutation.mutate();
          clearInterval(intervalId);
        }
      }, 1 * 1000); // run every 1 second

    } catch (error) {
      console.error("Error in handleSend:", error);
    }
  };

  useEffect(() => {
    if (isMaximized) {
      setIsMinimized(false);
    }
  }, [isMaximized])


  return (
    <>
      {showSendingModal && <MessageSent undoFunction={() => {
        setUndoSend(true);
        setHidden(false);
        setShowSendingModal(false);
        clearInterval(intervalId);
        cancelEmailSendMutation.mutate();
      }}

        closeFunction={() => {
          setShowSendingModal(false);
        }}
      />}
      {!hidden &&

        <>

          {isMinimized ? (

            <MinimizedMessage subject={subject} toggleMinimizedMutation={toggleMinimizedMutation} updateIsComposingMutation={updateIsComposingMutation}
              isMaximized={isMaximized} setIsMaximized={setIsMaximized} />
          ) : (
            <MessageContainer isMaximized={isMaximized} setIsMaximized={setIsMaximized}>
              <div className="bg-secondary-100 rounded-t-2xl ">
                <div className="p-3">
                  <div className="justify-between flex">
                    <div className="w-full"
                      onClick={() => toggleMinimizedMutation.mutate()}>
                      <p className='truncate text-base w-[450px]'>{subject || 'New Message'}</p>

                    </div>
                    <div className="flex items-center space-x-2 flex-shrink-0">
                      <button
                        className='p-1 '
                        onClick={() => setIsMinimized(!isMinimized)}>
                        <MinusIcon className='h-5 w-5' />
                      </button>
                      <button
                        className='p-1 '
                        onClick={() => setIsMaximized(!isMaximized)}>
                        {isMaximized ?
                          <FiMinimize2 className='h-5 w-5' /> :
                          <FiMaximize2 className='h-5 w-5' />}
                      </button>
                      <button
                        className='p-1 '
                        onClick={() => updateIsComposingMutation.mutate()}>
                        <XIcon className='h-5 w-5' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-3 ">
                <div className="border-b border-gray-300 pb-3">
                  <HoverEditComponent
                    editable={true}
                    defaultComponent={
                      <>
                        {toEmail ? (
                          <p className='mt-1.5'>
                            {toEmail}
                            {ccEmail ? `, ${ccEmail}` : ''}
                          </p>
                        ) : (
                          <p className='text-gray-500'>Recipients</p>
                        )}
                      </>
                    }
                    editComponent={
                      <div className='space-y-2 relative'>
                        <div className="flex items-center space-x-2 flex-wrap space-y-2 -mt-2">
                          <label htmlFor='toEmail' className='text-gray-600 mt-1.5'>To</label>
                          {/* <input
                        type="text"
                        value={toEmail}
                        id='toEmail'
                        onChange={(e) => {
                          setToEmail(e.target.value);
                          updateRecipientsMutation.mutate({ to_email: e.target.value, cc_email: ccEmail, bcc_email: bccEmail });
                        }}
                        className="py-0 outline-none border-none focus:ring-0 px-0 w-full"
                        placeholder=""
                      /> */}
                          {toEmailArray && toEmailArray.map((email, index) => (
                            <div key={index} className="flex items-center space-x-2 border text-gray-500 border-gray-300 rounded-full py-1 px-2 ">
                              <p className='-my-1'>{email}</p>
                              <button
                                onClick={() => {
                                  const newEmails = toEmailArray.filter((_, i) => i !== index).join(', ');
                                  setToEmail(newEmails);
                                  updateRecipientsMutation.mutate({ to_email: newEmails, cc_email: ccEmail, bcc_email: bccEmail });
                                }}
                              >
                                <XIcon className='h-5 w-5 text-gray-500' />
                              </button>
                            </div>
                          ))}
                          <UserSearch
                            setSelectedUser={(user) => {
                              const newEmail = user?.email || `${user}`
                              console.log(newEmail);
                              const newEmails = toEmailArray.includes(newEmail)
                                ? toEmailArray
                                : [...toEmailArray, newEmail].join(', ');
                              setToEmail(newEmails);
                              console.log(newEmails);
                              updateRecipientsMutation.mutate({ to_email: newEmails, cc_email: ccEmail, bcc_email: bccEmail });
                            }}
                          />
                        </div>
                        {showCC && <div className="flex items-center space-x-2 flex-wrap">
                          <label htmlFor='ccEmail' className='text-gray-600 mt-1.5'>Cc</label>
                          {ccEmailArray && ccEmailArray.map((email, index) => (
                            <div key={index} className="flex items-center space-x-2 border text-gray-500 border-gray-300 rounded-full py-1 px-2 ">
                              <p>{email}</p>
                              <button
                                onClick={() => {
                                  const newEmails = ccEmailArray.filter((_, i) => i !== index).join(', ');
                                  setCcEmail(newEmails);
                                  updateRecipientsMutation.mutate({ to_email: toEmail, cc_email: newEmails, bcc_email: bccEmail });
                                }}
                              >
                                <XIcon className='h-5 w-5 text-gray-500' />
                              </button>
                            </div>
                          ))}
                          <UserSearch
                            setSelectedUser={(user) => {
                              const newEmail = user?.email || `${user}`
                              const newEmails = ccEmailArray.includes(newEmail)
                                ? ccEmailArray
                                : [...ccEmailArray, newEmail].join(', ');
                              setCcEmail(newEmails);
                              updateRecipientsMutation.mutate({ to_email: toEmail, cc_email: newEmails, bcc_email: bccEmail });
                            }}
                          />
                        </div>}
                        {showBCC && <div className="flex items-center space-x-2">
                          <label htmlFor='bccEmail' className='text-gray-600 mt-1.5'>Bcc</label>
                          {bccEmailArray && bccEmailArray.map((email, index) => (
                            <div key={index} className="flex items-center space-x-2 border text-gray-500 border-gray-300 rounded-full py-1 px-2 ">
                              <p>{email}</p>
                              <button
                                onClick={() => {
                                  const newEmails = bccEmailArray.filter((_, i) => i !== index).join(', ');
                                  setBccEmail(newEmails);
                                  updateRecipientsMutation.mutate({ to_email: toEmail, cc_email: ccEmail, bcc_email: newEmails });
                                }}
                              >
                                <XIcon className='h-5 w-5 text-gray-500' />
                              </button>
                            </div>
                          ))}
                          <UserSearch
                            setSelectedUser={(user) => {
                              const newEmail = user?.email || `${user}`
                              const newEmails = bccEmailArray.includes(newEmail)
                                ? bccEmailArray
                                : [...bccEmailArray, newEmail].join(', ');
                              setBccEmail(newEmails);
                              updateRecipientsMutation.mutate({ to_email: toEmail, cc_email: ccEmail, bcc_email: newEmails });
                            }}
                          />
                        </div>}
                        <div className="flex items-center justify-end  space-x-2 text-gray-700 pr-2 absolute bottom-0 right-0">
                          {!showCC && <button
                            className=''
                            onClick={() => setShowCC(true)}
                          >Cc</button>}
                          {
                            !showBCC && <button
                              className=''
                              onClick={() => setShowBCC(true)}
                            >Bcc</button>
                          }
                        </div>
                      </div>
                    }
                  />
                </div>
                <input
                  type="text"
                  className="py-3 outline-none focus:ring-0 border-b border-t-0 border-l-0 px-0 border-gray-300 border-r-0 w-full focus:border-gray-300"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                    updateSubjectMutation.mutate(e.target.value);
                  }}
                  placeholder="Subject"
                />
                <div className="mt-3 max-h-[350px] overflow-y-auto custom-scrollbar-thick   custom-scrollbar-gray">
                  <QuillTextEditor
                    placeholder=""
                    options={options}
                    queryKey={['email-quill-editor', id]}
                    listQueryKey={'drafts_being_composed'}
                    setAcceptOutsideChange={setQuillEditorAcceptOutsideChange}
                    acceptOutsideChange={quillEditorAcceptOutsideChange}
                  />
                </div>
                <div className="mt-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-6">
                      <button
                        onClick={() => {
                          handleSend()
                        }}
                        className="btn-primary-rounded btn-lg">{
                          sendEmailMutation.isLoading ? <SmallLoadingSpinner textColor='text-white' /> : 'Send'
                        }
                      </button>
                      <div className="flex  items-center">
                        <ScheduledSend id={id} />
                        <Templates emailId={id} toggleAcceptChange = {() => setQuillEditorAcceptOutsideChange(true)}  />
                      </div>
                     
                    </div>
                    <button
                      onClick={() => modifyLabelsMutation.mutate({ addLabelIds: ['TRASH'], removeLabelIds: [] })
                      }
                    >
                      <TrashIcon className='h-6 w-6 text-gray-600' />
                    </button>
                  </div>
                </div>
              </div>
            </MessageContainer>)}
        </>
      }
    </>
  );
};

export default Message;
